<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가기획마법사</h1>
    <div class="box-wrap ">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="grid01Search.ASP_NEWCUST_KEY"
              :readonly="RDOnly"
            >
            <template v-slot:label>
                회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01Search.LM_TY"
            >
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="시험명" id="id" outlined hide-details=""
            v-model="grid01Search.LM_ID_NM"
            @keyup.enter="selectRtnLm()"
            >
          </v-text-field>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="전체시험기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="grid01Search.START_DATE"
                no-title
                dark
                color="#F7AFAE"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="grid01Search.END_DATE"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="grid01Search.START_DATE"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2" @click="selectRtnLm()"> 조회 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined class="btn-point ml-2" :disabled="(!isSelected || !isModify)" @click="updateRtnLmForm()"> 수정 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')" outlined class="btn-point ml-2" @click="insertRtnLmForm()"> 등록 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" :disabled="(!isSelected || !isModify)" @click="grid01DeleteAlert()"> 삭제 </v-btn>
        </div>
        <v-dialog max-width="655" persistent v-model="dialogM410201P01" v-if="dialogM410201P01"
          content-class="square-modal min-auto">
          <dialog-M410201P01
            p01
            headerTitle="시험 기획"
            :isModify="mFlag"
            :ASP_NEWCUST_KEY="grid01Search.ASP_NEWCUST_KEY"
            :PLT018="mixin_common_code_get(allCodeList,'PLT018','선택')"
            @hide="hideDialog('M410201P01')"
            @submit="insertRtnLmCallBack()"
            :grid01Selected="mFlag? grid01Selected: {}"
          >
          </dialog-M410201P01>
        </v-dialog>
      </div>
    </div>
    <!-- 평가 기획 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">시험 기획 목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="grid01Headers"
          :items="grid01Data"
          item-key="LM_ID"
          hide-default-footer
          class="grid-default"
          height="434px"
          fixed-header
          @click:row="setGrid01Select"
          :no-data-text="noDataTable"
          :item-class="isActiveRow"
        >
        </v-data-table>
      </div>
    </div>
    <!-- 탭 -->
    <div class="box-wrap">
      <!-- 버튼 -->
      <div class="absolute-left-menu">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFin')" outlined class="btn-etc ml-2" :disabled="!isSelected || grid01Selected.LM_PG_ST == '99'" @click="common_confirm('기획을 종료하시겠습니까?', updateRtnEndLm(),'',null,'','chk')"> 종료 </v-btn>
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" :disabled="(!isSelected || !isModify) || grid01Selected.LM_PG_ST == '99'" @click="common_confirm('저장하시겠습니까?', processRtn,'', null,'', 'chk')"> 저장 </v-btn>
      </div>
      <v-tabs
        class="tabs-form"
        slider-color="#6C6E7A"
        slider-size="1"
        v-model="activeTab"
      >
        <v-tab>시험 기획 정보</v-tab>
        <v-tab>대상자 발췌</v-tab>

        <!-- 시험 기획 정보 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <div>
              <div class="form-inp sm">
                <v-text-field
                class="form-inp"
                name="name" label="유형타입" id="id" outlined hide-details="" disabled :value="grid01Selected.LM_TY_NM">

              </v-text-field>
              </div>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="시험명" id="id" outlined hide-details="" disabled :value="grid01Selected.LM_ID_NM">

              </v-text-field>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="기획자명" id="id" outlined hide-details="" disabled :value="grid01Selected.LM_TG_PL_NM">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="기획자ID" id="id" outlined hide-details="" disabled :value="grid01Selected.LM_TG_PL_ID">
              </v-text-field>
              <v-text-field
                  outlined
                  hide-details
                  class="form-inp icon-calendar lg ml-2"
                  append-icon="svg-calendar"
                  label="시험일시"
                  disabled
                  :value="grid01Selected.LM_ST_DTTM != null? grid01Selected.LM_ST_DTTM + ' ~ ' + grid01Selected.LM_EN_DTTM : ''"
              ></v-text-field>
              <div class="form-inp ml-2">
                <v-select
                  :items="evlPaperSelectBox"
                  item-text="LM_EVA_NM"
                  item-value="LM_EVA_ID"
                  outlined
                  hide-details
                  label="시험지명"
                  placeholder="선택하세요"
                  :disabled="(!isSelected || !isModify)"
                  v-model="tab01.LM_EVA_ID"
                >
                </v-select>
              </div>
              <!-- 조회 버튼 -->
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch2')"
                dark small
                class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                :disabled="(!isSelected || !isModify)" @click="showDialog('M410201P02')">
                <v-icon small class="svg-main-searh white"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPreview')" outlined class="btn-default ml-2" :disabled="!isSelected || this.tab01.LM_EVA_ID == ''" @click="selectRtnLmPreView()">미리보기</v-btn>
              <v-dialog
                max-width="1400"
                max-height="600"
                persistent
                v-model="dialogM410101P04"
                content-class="square-modal modal-white"
              >
                <dialog-M410101P01
                  headerTitle="미리보기"
                  p04
                  :preEvlRstData="preViewLmQs"
                  :preViewList="preViewVe"
                  @hide="hideDialog('M410101P04')"
                >
                </dialog-M410101P01>
              </v-dialog>
              <v-dialog max-width="655" persistent v-model="dialogM410201P02" v-if="dialogM410201P02"
                content-class="square-modal modal-white">
                <dialog-M410201P01
                  p02
                  headerTitle="평가표 검색"
                  mFlag
                  :ASP_NEWCUST_KEY="grid01Search.ASP_NEWCUST_KEY"
                  :PLT018="mixin_common_code_get(allCodeList,'PLT018','전체')"
                  :PLT012="mixin_common_code_get(allCodeList,'PLT012','전체')"
                  @hide="hideDialog('M410201P02')"
                  @selected="selectEvlPaper"
                ></dialog-M410201P01>
              </v-dialog>
            </div>
            <div class="mt-2">
              <v-text-field
                class="form-inp sm"
                name="name" label="제한시간(분)" type="number" id="id" outlined hide-details="" min="0" :disabled="(!isSelected || !isModify)" v-model="tab01.LM_LIM_TIME">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="목표점수" type="number" id="id" outlined hide-details="" min="0" :disabled="(!isSelected || !isModify)" v-model="tab01.LM_TG_SC">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="진행상태" id="id" outlined hide-details="" disabled :value="grid01Selected.LM_PG_ST_NM">
              </v-text-field>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="제출 현황" id="id" outlined hide-details="" disabled :value="lmStat">
              </v-text-field>
            </div>
          </div>
        </v-tab-item>
        <!-- 대상자 발췌 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <h3 class="tit-h3">대상 조건</h3>
            <div class="d-flex">
              <div>
                <div class="mt-2">
                  <div class="form-inp sm ">
                    <v-select
                      :items="user_auth_code"
                      item-text="NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="그룹명"
                      placeholder="선택하세요"
                      v-model="tab02.LM_ATRT_GROUP_ID"
                      :disabled="(!isSelected || !isModify)"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="this.userAttrA"
                      item-text="CD_NM"
                      item-value="CD"
                      label="소속A"
                      outlined
                      hide-details
                      no-data-text="전체"
                      v-model="tab02.USER_ATTR_A"
                      :disabled="(!isSelected || !isModify)"
                    ></v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="this.userAttrB"
                      item-text="CD_NM"
                      item-value="CD"
                      label="소속B"
                      outlined
                      hide-details
                      no-data-text="전체"
                      v-model="tab02.USER_ATTR_B"
                      :disabled="(!isSelected || !isModify)"
                    ></v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="this.userAttrC"
                      item-text="CD_NM"
                      item-value="CD"
                      label="소속C"
                      outlined
                      hide-details
                      no-data-text="전체"
                      v-model="tab02.USER_ATTR_C"
                      :disabled="(!isSelected || !isModify)"
                    ></v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="this.userAttrD"
                      item-text="CD_NM"
                      item-value="CD"
                      label="소속D"
                      outlined
                      hide-details
                      no-data-text="전체"
                      v-model="tab02.USER_ATTR_D"
                      :disabled="(!isSelected || !isModify)"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="ml-auto align-self-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch3')" outlined class="btn-default" @click="selectRtnLmUser()" :disabled="(!isSelected || !isModify)">조회</v-btn>
              </div>

            </div>
            <!-- 그리드 -->
            <div class="mt-3">
              <v-data-table
                dense
                :headers="grid02Headers"
                :items="grid02Data"
                item-key="LM_DATA_US_ID"
                hide-default-footer
                class="grid-default"
                height="434px"
                show-select
                fixed-header
                :page.sync="grid02Paging.page"
                :items-per-page="30"
                @page-count="grid02Paging.pageCount = $event"
                no-data-text="검색된 결과가 없습니다."
                v-model="grid02Selected"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="grid02Paging.page"
                  :length="grid02Paging.pageCount"
                  :total-visible="grid02Paging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
            <div class="d-flex justify-center align-center">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow')" class="btn-arrow" plain small @click="moveUp()" :disabled="(!isSelected || !isModify)">
                <v-icon small class="svg-arrow-top"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow')" class="btn-arrow ml-2" plain small @click="moveDown()" :disabled="(!isSelected || !isModify)">
                <v-icon small class="svg-arrow-down"></v-icon>
              </v-btn>
            </div>
            <div class="mt-3">
              <v-data-table
                dense
                :headers="grid03Headers"
                :items="grid03Data"
                item-key="LM_DATA_US_ID"
                hide-default-footer
                class="grid-default"
                height="434px"
                show-select
                fixed-header
                :page.sync="grid03Paging.page"
                :items-per-page="30"
                @page-count="grid03Paging.pageCount = $event"
                no-data-text="검색된 결과가 없습니다."
                v-model="grid03Selected"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="grid03Paging.page"
                  :length="grid03Paging.pageCount"
                  :total-visible="grid03Paging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import DialogM410201P01 from "./M410201P01.vue";
import DialogM410101P01 from './M410101P01.vue'
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M410201", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM410201P01,
    DialogM410101P01
  },
  mixins:[mixin], // mixin 등록
  data() {
    return {
      dialogM410201P01: false,
      dialogM410201P02: false,
      dialogM410101P01: false,
      dialogM410101P04: false,
      menu1: false,
      mFlag : false,
      activeTab : 0,
      LM_PG_ST : [],
      groupCdList : ['PLT012','PLT018', 'PLT019', 'PLT020', 'PLT021'],
      allCodeList : [],
      noDataTable:"검색된 결과가 없습니다.",

      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      grid01Headers:[
        {
          text: "No",
          align: "center",
          value: "LM_ID",
          width: "70px",
          sortable: true,
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "LM_TY_NM", align: "center", sortable: true },
        { text: "시험명", value: "LM_ID_NM", align: "center", sortable: true },
        { text: "진행상태", value: "LM_PG_ST_NM", align: "center", sortable: true },
        { text: "시험시작일", value: "LM_ST_DTTM", align: "center", sortable: true },
        { text: "시험종료일", value: "LM_EN_DTTM", align: "center", sortable: true },
        { text: "제한시간", value: "LM_LIM_TIME", align: "center", sortable: true },
        { text: "문제수", value: "LM_EVA_RST_COUNT", align: "center", sortable: true },
        { text: "인원", value: "LM_DATA_COUNT", align: "center", sortable: true },
        { text: "제출자", value: "LM_DONE_CNT", align: "center", sortable: true },
        { text: "미제출자", value: "LM_NON_DONE_CNT", align: "center", sortable: true },
        { text: "총점", value: "LM_EVA_SUM", align: "center", sortable: true },

      ],
      grid01Data:[],
      grid01Selected: {},
      grid01Search:{
        ASP_NEWCUST_KEY : "",
        LM_TY : "",
        LM_NM : "",
        START_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        END_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      evlPaperSelectBox : [],
      grid02Headers:[
        {text:"회사구분", align:"center", value:"ASP_CUST_NM",width:120, sortable: true,},
        {text:"ID", align:"center", value:"LM_DATA_US_ID", width:120, sortable: true,},
        {text:"성명", align:"center", value:"LM_DATA_US_NM", width:100, sortable: true,},
        {text:"그룹명", align:"center", value:"ATRT_GROUP_NM", width:100, sortable: true,},
        {text:"소속A", align:"center", value:"USER_ATTR_A_NM", width:100, sortable: true,},
        {text:"소속B", align:"center", value:"USER_ATTR_B_NM", width:100, sortable: true,},
        {text:"소속C", align:"center", value:"USER_ATTR_C_NM", width:100, sortable: true,},
        {text:"소속D", align:"center", value:"USER_ATTR_D_NM", width:100, sortable: true,},

      ],
      grid02Data:[],
      grid02Selected:[],
      grid02Paging:{
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalVisible: 10,
        totalCount : 0,
      },
      grid03Headers:[
        {text:"회사구분", align:"center", value:"ASP_CUST_NM",width:120, sortable: true,},
        {text:"ID", align:"center", value:"LM_DATA_US_ID", width:120, sortable: true,},
        {text:"성명", align:"center", value:"LM_DATA_US_NM", width:100, sortable: true,},
        {text:"그룹명", align:"center", value:"ATRT_GROUP_NM", width:100, sortable: true,},
        {text:"소속A", align:"center", value:"USER_ATTR_A_NM", width:100, sortable: true,},
        {text:"소속B", align:"center", value:"USER_ATTR_B_NM", width:100, sortable: true,},
        {text:"소속C", align:"center", value:"USER_ATTR_C_NM", width:100, sortable: true,},
        {text:"소속D", align:"center", value:"USER_ATTR_D_NM", width:100, sortable: true,},
      ],
      grid03Data:[],
      grid03Selected:[],
      grid03DelDataId:[],
      grid03Paging:{
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalVisible: 10,
        totalCount : 0,
      },
      gridSelectBox : {
        QA_CNSL_TYP_CD : [],
        QA_CNSL_TYP_CD2 : [],
        QA_CNSL_TYP_CD3 : [],
        QA_CNSL_TYP_CD4 : [],
      },
      tab01 : {
        LM_ID : "",
        LM_EVA_ID : "",
        LM_ID_NM : "",
        LM_TY : "",
        LM_ST_DTTM : "",
        LM_EN_DTTM : "",
        LM_TG_SC : "",
        LM_PG_ST : "",
        LM_PG_ST_NM : "",
        LM_LIM_TIME : "",
        ASP_NEWCUST_KEY : "",
        REG_ID : "",
      },
      preViewLmQs : [],
      preViewVe : [],
      tab02 : {
        LM_ATRT_GROUP_ID : "",
        USER_ATTR_A : "",
        USER_ATTR_B : "",
        USER_ATTR_C : "",
        USER_ATTR_D : "",
      },
      userAttrA:[],
      userAttrB:[],
      userAttrC:[],
      userAttrD:[],
      user_auth_code:[],

      RDOnly:false,
    };
  },
  watch:{

  },
  created() {
    // this.getCmmCd(this.groupCdList,'','','');
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
    this.user_auth_code = await this.mixin_user_auth_get("전체");

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.grid01Search.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    /*===========================데이터 조회 시작 ============================*/
    //평가 기획 조회
    async selectRtnLm(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/plan/lm-manage/list";
        requestData.headers["SERVICE"] = "phone.plan.lm-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.grid01Data = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }
    },

    //시험지 selectbox 조회
    async selectRtnEvlPaper(){

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/evl-paper/list";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.evl-paper";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData = this.grid01Search;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.evlPaperSelectBox = response.DATA;
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },

    grid01DeleteAlert(){
      if(this.isSelected){
        if(this.grid01Selected.LM_PG_ST == '10'){
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "정말 삭제하시겠습니까?",
            iconClass: "svg-chk-lg",
            type: "confirm",
            callYes: () => {
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: false,
              });
              this.deleteRtnLm();
            },
            callNo: () => {
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: false,
              });
            },
          });
        }else{
          this.common_alert('진행상태가 기획중인 시험 기획만 삭제할 수 있습니다.', 'error')
        }
      }else{
        this.common_alert("선택된 항목이 없습니다.", 'error');
      }
    },
    async deleteRtnLm(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/delete";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert("정상처리 되었습니다.","done");
        this.grid01Selected = [];
        this.initTabData();
        this.selectRtnLm();
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    //평가기획 종료
    async updateRtnEndLm(){

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/detail/regist";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.detail";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
      requestData.sendData['LM_PG_ST'] = '99';
      requestData.sendData['REG_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert("정상처리 되었습니다.","done");
        this.selectRtnLm();
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async selectRtnLmUser(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/user/list";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.user";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
      requestData.sendData['ATRT_GROUP_ID'] = this.tab02.LM_ATRT_GROUP_ID;
      requestData.sendData['USER_ATTR_A'] = this.tab02.USER_ATTR_A;
      requestData.sendData['USER_ATTR_B'] = this.tab02.USER_ATTR_B;
      requestData.sendData['USER_ATTR_C'] = this.tab02.USER_ATTR_C;
      requestData.sendData['USER_ATTR_D'] = this.tab02.USER_ATTR_D;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.grid02Data = response.DATA;
        console.log(this.grid02Data)
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async selectRtnLmData(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/data/list";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.data";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;
      requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
      requestData.sendData['LM_EVA_ID'] = this.tab02.LM_EVA_ID;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.grid03Data = response.DATA;
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },

    async processRtn(){

      let requestData = {
        headers: {},
        sendData:{}
      };

      let DATA_OBJECT = '';

      if(this.activeTab == 0){
        //시험 기획 정보
        requestData.sendData = {
          LM_ID : this.tab01.LM_ID,
          ASP_NEWCUST_KEY : this.tab01.ASP_NEWCUST_KEY,
          REG_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          LM_EVA_ID : this.tab01.LM_EVA_ID,
          LM_TG_SC : this.tab01.LM_TG_SC,
          LM_LIM_TIME : this.tab01.LM_LIM_TIME,
          STEP: '1',
        };
      }else if(this.activeTab == 1){
        DATA_OBJECT = "LM_DATA_LIST,DEL_LM_DATA_ID";
        requestData.sendData = {
          ASP_NEWCUST_KEY : this.tab02.ASP_NEWCUST_KEY,
          LM_ID : this.tab02.LM_ID,
          LM_EVA_ID : this.tab02.LM_EVA_ID,
          REG_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          LM_ATRT_GROUP_ID : this.tab02.LM_ATRT_GROUP_ID,
          USER_ATTR_A : this.tab02.USER_ATTR_A,
          USER_ATTR_B : this.tab02.USER_ATTR_B,
          USER_ATTR_C : this.tab02.USER_ATTR_C,
          USER_ATTR_D : this.tab02.USER_ATTR_D,
          LM_DATA_LIST : this.grid03Data,
          STEP : '2',
          DEL_LM_DATA_ID : this.grid03DelDataId,
        };

        if(this.tab02.LM_EVA_ID == undefined || this.tab02.LM_EVA_ID == ''){
          this.common_alert("시험 기획 정보탭에서 시험지 저장 후 다시 시도해주세요.","error");
          return;
        }

        if(this.grid03Data.length == 0){
          this.common_alert("대상자가 설정되지 않았습니다.", "error");
          return;
        }
      }


      requestData.headers["URL"] = "api/phone/plan/lm-manage/detail/regist";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.detail";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["DATA_OBJECT"] = DATA_OBJECT;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert("정상처리 되었습니다.","done");
        this.selectRtnLm();
        if(this.activeTab == 0){
          this.tab02.LM_EVA_ID = this.tab01.LM_EVA_ID;
        }else if(this.activeTab == 1){
          this.grid03DelDataId = [];
        }
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    //시험지 미리보기 조회
    async selectRtnLmPreView(){
      if(this.isSelected && this.tab01.LM_EVA_ID != ''){
        let jsonData = {
          LM_EVA_ID : this.tab01.LM_EVA_ID,
          ASP_NEWCUST_KEY : this.grid01Search.ASP_NEWCUST_KEY,
        };

        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/evl-lm-rst-manage/pre/all/list";
        requestData.headers["SERVICE"] = "phone.lm.evl-lm-rst-manage.pre.all";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['ASP_NEWCUST_KEY'] = this.grid01Selected.ASP_NEWCUST_KEY;
        requestData.sendData['LM_EVA_ID'] = this.tab01.LM_EVA_ID;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.preViewLmQs = response.lmQsList;
          this.preViewVe = response.veList;
          this.showDialog('M410101P04');
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      }else{
        this.common_alert("시험지를 선택해주세요.","error");
      }
    },

    /*===========================데이터 조회 끝============================*/
    insertRtnLmForm(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.mFlag = false;
        this.showDialog('M410201P01');
      }
    },
    updateRtnLmForm(){
      this.mFlag = true;
      this.showDialog('M410201P01');
    },
    insertRtnLmCallBack(){
      this.selectRtnLm();
      this.grid01Selected = {};
      this.submitDialog('M410201P01');
    },
    //기타
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    async setGrid01Select(item){
      this.grid01Selected = Object.assign({}, item);
      this.tab01 = Object.assign({}, item);
      this.tab02 = Object.assign({}, item);
      this.selectRtnEvlPaper();
      this.selectRtnLmData();
      this.userAttrA = await this.mixin_attr_get(this.grid01Selected.ASP_NEWCUST_KEY,'A',"전체");
      this.userAttrB = await this.mixin_attr_get(this.grid01Selected.ASP_NEWCUST_KEY,'B',"전체");
      this.userAttrC = await this.mixin_attr_get(this.grid01Selected.ASP_NEWCUST_KEY,'C',"전체");
      this.userAttrD = await this.mixin_attr_get(this.grid01Selected.ASP_NEWCUST_KEY,'D',"전체");
      this.grid02Selected = [];
      this.grid02Data = [];
    },
    selectEvlPaper(item){
      this.tab01.LM_EVA_ID = item[0].LM_EVA_ID;
      this.submitDialog('M410201P02');
    },
    initTabData(){
      this.tab01 = {
        LM_ID : "",
        LM_EVA_ID : "",
        LM_ID_NM : "",
        LM_TY : "",
        LM_ST_DTTM : "",
        LM_EN_DTTM : "",
        LM_TG_SC : "",
        LM_PG_ST : "",
        LM_PG_ST_NM : "",
        LM_LIM_TIME : "",
        ASP_NEWCUST_KEY : "",
        REG_ID : "",
      };

      this.tab02 = {
        LM_ATRT_GROUP_ID : "",
        USER_ATTR_A : "",
        USER_ATTR_B : "",
        USER_ATTR_C : "",
        USER_ATTR_D : "",
      };
    },
    moveDown(){
      let count = 0;
      if(this.grid02Selected.length > 0){
        this.grid02Selected.map(e =>{
          if(!this.grid03Data.some(grid03 => grid03.LM_DATA_US_ID === e.LM_DATA_US_ID)){
            this.grid03Data.push(e);
            count++;
          }
        });

        if(this.grid02Selected.length != count){
          this.common_alert("중복된 대상자가 존재합니다.","error");
        }
      }else{
        this.common_alert("대상자를 선택해주세요.","error");
      }

      this.grid02Selected = [];
    },

    moveUp(){
      if(this.grid03Selected.length > 0){
        this.grid03Selected.map(e => {
          this.grid03Data.some((grid03, index) => {
            if(grid03 === e){
              if(e.LM_DATA_ID != undefined && e.LM_DATA_ID != ""){
                this.grid03DelDataId.push({"LM_DATA_ID":e.LM_DATA_ID});
              }
              this.grid03Data.splice(index,1);
            }
          })
        });
      }else{
        this.common_alert("대상자를 선택해주세요.","error")
      }
      this.grid03Selected = [];
    },
    isActiveRow(item){
      return item.LM_ID == this.grid01Selected.LM_ID? 'active':'';
    },
  },
  computed: {
    searchDateRangeText(){
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      return newStartDate + " ~ " + newEndDate;
    },
    cnslDateRangeText() {
      var newStartDate = this.tab02.QA_TG_ST_DTTM;
      var newEndDate = this.tab02.QA_TG_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
    isSelected(){
      let isSelected = false;
      if(this.grid01Selected.LM_ID != null && this.grid01Selected.LM_ID != ''){
        isSelected = true;
      }
      return isSelected;
    },
    isModify(){
      let isModify = false;

      if(this.grid01Selected.LM_ID != null && this.grid01Selected.LM_ID != ''){
        if(this.grid01Selected.LM_PG_ST == '10'){
          isModify = true;
        }
      }
      return isModify;
    },
    toDay(){
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
    },
    lmStat(){
      let msg = "";
      if(!this.mixin_isEmpty(this.grid01Selected.LM_DONE_CNT)){
        msg = "시험제출자 :"+ this.grid01Selected.LM_DONE_CNT + "명 "
        msg += "시험미제출자 :" + this.grid01Selected.LM_NON_DONE_CNT + "명 "
      }

      return msg;
    }
  },
};
</script>

<style>

</style>