<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <template v-if="p01">
        <div class="gray-box">
          <div class="form-inp sm">
            <v-select
              :items="PLT018"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01.LM_TY"
            >
            <template v-slot:label>
                유형타입
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="시험명" id="id"
            outlined hide-details=""
            v-model.trim="grid01.LM_ID_NM"
            error-count="50"
            >
            <template v-slot:label>
                시험명
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-text-field>
          <div class="mt-3">
            <v-menu
              ref="lmEnDttmMenu1"
              v-model="lmStDttmMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateLmStDttm"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  label="시작일시"
                  class="form-inp icon-calendar"
                  append-icon="svg-calendar"
                ></v-text-field>
              </template>
              <div class="d-flex align-center">
                <v-date-picker
                    v-model="grid01.LM_ST_DATE"
                    dark
                    color="#F7AFAE"
                    no-title
                    show-current="false"
                    :first-day-of-week="1"
                    locale="ko-KR"
                    :day-format="mixin_getDate"
                ></v-date-picker>
                <v-time-picker
                    v-model="grid01.LM_ST_TIME"
                    :allowed-minutes="allowedStep"
                    color="#F7AFAE"
                    format="24hr"
                    @click:minute="$refs.lmEnDttmMenu1.save(time)"
                ></v-time-picker>
              </div>
            </v-menu>
            <v-menu
              ref="lmEnDttmMenu2"
              v-model="lmEnDttmMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateLmEnDttm"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  label="종료일시"
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                ></v-text-field>
              </template>
              <div class="d-flex align-center">
                <v-date-picker
                    v-model="grid01.LM_EN_DATE"
                    dark
                    color="#F7AFAE"
                    no-title
                    show-current="false"
                    :first-day-of-week="1"
                    locale="ko-KR"
                    :day-format="mixin_getDate"
                ></v-date-picker>
                <v-time-picker
                    v-model="grid01.LM_EN_TIME"
                    :allowed-minutes="allowedStep"
                    color="#F7AFAE"
                    format="24hr"
                    @click:minute="$refs.lmEnDttmMenu2.save()"
                ></v-time-picker>
              </div>
            </v-menu>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',insertRtnLm,'',null,'','done')">저장</v-btn>
        </div>
      </template>
      <template v-if="p02">
        <div class="d-flex gray-box">
          <div>
            <div class="form-inp sm">
              <v-select
                :items="PLT018"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형타입"
                placeholder="선택하세요"
                v-model="evlPaperSearch.LM_EVA_TY"
                @change="selectRtnLmEva()"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT012"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="사용여부"
                placeholder="선택하세요"
                v-model="evlPaperSearch.LM_EVA_YN"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가표명" outlined hide-details
              v-model="evlPaperSearch.LM_EVA_NM"
              @keyup.enter="selectRtnLmEva()"
              >
            </v-text-field>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="selectRtnLmEva()">조회</v-btn>
          </div>
        </div>
        <div class="table-form mt-2">
          <v-data-table
            dense
            :headers="evlPaperHeader"
            :items="evlPaperData"
            item-key="LM_EVA_ID"
            hide-default-footer
            class="grid-default"
            show-select
            single-select
            height="200"
            no-data-text="검색된 결과가 없습니다."
            :page.sync="evlPaperPaging.page"
            :items-per-page="30"
            @page-count="evlPaperPaging.pageCount = $event"
            v-model="selectedEvlPaper"
          >
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="evlPaperPaging.page"
              :length="evlPaperPaging.pageCount"
              :total-visible="evlPaperPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnChoice')" outlined class="btn-default ml-2" @click="selectEvlPaper()">선택</v-btn>
        </div>
      </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M410201P01", //name은 'MENU_' + 파일명 조합
  mixins : [mixin],
  props: {
    isModify: {
      type: Boolean,
      default: false,
    },
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
    PLT018:[],
    PLT012:[],
    grid01Selected:{},
    ASP_NEWCUST_KEY: {
      type: String,
    }
  },
  data: () => ({
    evlPaperHeader : [
        {
        text: 'No',
        align: 'center',
        value: 'LM_EVA_ID',
        width: '50px',
        sortable: false,
      },
      { text: '유형타입', value: 'LM_EVA_TY_NM', align: 'center', sortable: false, },
      { text: '시험지 명', value: 'LM_EVA_NM',align: 'center', sortable: false, },
      { text: '설명', value: 'LM_EVA_COMM', align: 'center',sortable: false, },
      { text: '총점', value: 'LM_EVA_SUM', align: 'center',sortable: false, },

    ],
    grid01:{
      LM_ID : "",
      LM_ID_NM : "",
      LM_TY : "",
      LM_ST_DTTM : "",
      LM_EN_DTTM : "",
      LM_ST_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      LM_ST_TIME : "09:00",
      LM_EN_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      LM_EN_TIME : "18:00",
    },
    evlPaperData: [],
    evlPaperSearch:{
      LM_EVA_NM : "",
      LM_EVA_TY : "",
      USE_YN : "",
    },
    selectedEvlPaper:{},
    evlPaperPaging:{
      page: 1,
      pageCount: 1,
      itemsPerPage: 5,
      totalVisible: 5,
    },
    footerHideTitle: "닫기",
    lmStDttmMenu: false,
    lmEnDttmMenu: false,
  }),
  created(){
    if(this.p01){
      if(this.isModify){
        this.grid01 = Object.assign({}, this.grid01Selected);
      }else{
      }
    }

    if(this.p02){
      this.selectRtnLmEva();
    }
  },
  methods: {
    startDate(e) {
      this.grid01.LM_ST_DTTM = e;
    },
    endDate(e) {
      this.grid01.LM_EN_DTTM = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    // 평가 기획 등록
    async insertRtnLm(){
      if(this.grid01.LM_TY == undefined || this.grid01.LM_TY == ''){
        this.common_alert("유형을 선택해주세요.","error");
      }else if(this.grid01.LM_ID_NM == undefined || this.grid01.LM_ID_NM == ''){
        this.common_alert("시험명을 입력해주세요.","error");
      }else{

        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/plan/lm-manage/regist";
        requestData.headers["SERVICE"] = "phone.plan.lm-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY,
        requestData.sendData['LM_ID'] = this.grid01.LM_ID;
        requestData.sendData['LM_ID_NM'] = this.grid01.LM_ID_NM;
        requestData.sendData['LM_TY'] = this.grid01.LM_TY;
        requestData.sendData['LM_ST_DATE'] = this.grid01.LM_ST_DATE;
        requestData.sendData['LM_ST_TIME'] = this.grid01.LM_ST_TIME;
        requestData.sendData['LM_EN_DATE'] = this.grid01.LM_EN_DATE;
        requestData.sendData['LM_EN_TIME'] = this.grid01.LM_EN_TIME;
        requestData.sendData['REG_NM'] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
        requestData.sendData['REG_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.common_alert("정상처리 되었습니다.","done");
          this.$emit('submit');
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      }
    },
    /* 평가표 리스트 조회 */
    async selectRtnLmEva(){

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/phone/plan/lm-manage/evl-paper/list";
      requestData.headers["SERVICE"] = "phone.plan.lm-manage.evl-paper";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY,
      requestData.sendData['LM_EVA_TY'] = this.evlPaperSearch.LM_EVA_TY;
      requestData.sendData['LM_EVA_YN'] = this.evlPaperSearch.LM_EVA_YN;
      requestData.sendData['LM_EVA_NM'] = this.evlPaperSearch.LM_EVA_NM;


      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.evlPaperData = response.DATA;
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    selectEvlPaper(){
      if(this.selectedEvlPaper.length > 0){
        this.$emit('selected', this.selectedEvlPaper);
      }else{
        this.common_alert("평가표를 선택해주세요.","noti");
      }
    },
    allowedStep: (m) => m % 10 === 0,
  },
  computed: {
    computedDateLmStDttm(){
      return `${this.grid01.LM_ST_DATE} ${this.grid01.LM_ST_TIME}`
    },
    computedDateLmEnDttm(){
      return `${this.grid01.LM_EN_DATE} ${this.grid01.LM_EN_TIME}`
    },
  },
};
</script>

<style></style>
